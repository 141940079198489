<template>
  <div>
    <div class="card" :class="{ card1: customer==='qingtian'}">
      <div class="title">
        <div class="left">
          <img
            v-if="customer==='qingtian'"
            src="@/assets/images/qingtian/基本信息.png"
            alt=""
          />
          <span v-if="customer==='jinlang'"></span>&nbsp;基本信息
        </div>
      </div>
      <div class="content">
        <ul>
          <li>东西长度（m）</li>
          <!-- <li>{{ baseInfo.EastWestLength }}</li> -->
          <li>{{ inpValW }}</li>
        </ul>
        <ul>
          <li>南北宽度（m）</li>
          <!-- <li>{{ baseInfo.NorthSouthWidth }}</li> -->
          <li>{{ inpValH }}</li>
        </ul>
        <ul>
          <li>房屋高度（m）</li>
          <li>{{ baseInfo.houseHeight }}</li>
        </ul>
        <ul>
          <li>房屋朝向</li>
          <li>{{ baseInfo.facingDirection }}</li>
        </ul>

        <ul v-show="baseInfo.facingDirection !== '正南'">
          <li>偏向度数（°）</li>
          <li>{{ baseInfo.facingDegree }}</li>
        </ul>
        <ul>
          <li>距并网点距离（m）</li>
          <li>{{ baseInfo.gridConnectionDistance }}</li>
        </ul>
        <ul>
          <li>屋面类型</li>
          <li>{{ baseInfo.roofingType }}</li>
        </ul>
        <ul>
          <li>屋面厚度（m）</li>
          <li>{{ baseInfo.roofSlabThickness }}</li>
        </ul>
        <ul>
          <li>安装方式</li>
          <li>{{ baseInfo.installationMethod }}</li>
        </ul>
      </div>
      <div>
        <img class="canvasimg" :src="canvasImg" alt="">
      </div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancelEvent">取消</div>
      <div class="addcancel" @click="save" :disabled="stopEnd">保存数据</div>
      <button class="determine" @click="submit" :disabled="stopEnd">提交踏勘信息</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRouter } from 'vue-router'
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { Dialog, Toast } from 'vant'
import { AddFlatRoof, AddTask, GetTaskState, ProjectErrorInfo } from "@/api/api"

export default {
  setup() {
    const router = useRouter()

    var baseInfo = JSON.parse(sessionStorage.getItem('requestFlatRoof'))
    if (baseInfo.roofingType === 1) {
      baseInfo.roofingType = "现浇板"
    } else {
      baseInfo.roofingType = "预制板"
    }
    if (baseInfo.facingDirection === 1) {
      baseInfo.facingDirection = "南偏东"
    } else if (baseInfo.facingDirection === 2) {
      baseInfo.facingDirection = "南偏西"
    } else {
      baseInfo.facingDirection = "正南"
    }
    if (baseInfo.installationMethod === 1) {
      baseInfo.installationMethod = "混泥土基墩"
    } else if (baseInfo.installationMethod === 2) {
      baseInfo.installationMethod = "膨胀螺栓"
    } else if (baseInfo.installationMethod === 3) {
      baseInfo.installationMethod = "混凝土+膨胀螺栓"
    }

    // baseInfo.gridConnectionDistance = (baseInfo.gridConnectionDistance / 10 / 100).toFixed(2)
    // baseInfo.houseHeight = (baseInfo.houseHeight / 10 / 100).toFixed(2)
    // baseInfo.roofSlabThickness = (baseInfo.roofSlabThickness / 10 / 100).toFixed(2)
    baseInfo.gridConnectionDistance = baseInfo.gridConnectionDistance / 1000
    baseInfo.houseHeight = baseInfo.houseHeight / 1000
    baseInfo.roofSlabThickness = baseInfo.roofSlabThickness / 1000

    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      // baseInfo: {},
      customer: window.customer,
      toast: null,
      timer: null,
      second: 40,
      inpValW: sessionStorage.getItem('inpValW') / 100,
      inpValH: sessionStorage.getItem('inpValH') / 100,
      // inpValW: 0,
      // inpValH: 0,
      nulldata: {},
      stopEnd: false,
      canvasImg: sessionStorage.getItem('flatCanvas'),
      obj1: '',
      timeLessen: 20,
    })
    onMounted(() => {
      // router.afterEach((to, from, next) => {
      //   if (from.path === '/flatRoof' && to.path === '/flatRoofPreview') {
      //     console.log('--------')
      //     // 刷新页面方法
      //     location.reload()
      //   }
      // })
    })
    const methodsMap = {
      cancelEvent() {
        router.back()
      },
      getDate() {
        var datetime = new Date();
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        return year + '/' + month + '/' + date
      },
      save() {
        data.stopEnd = true
        setTimeout(() => {
          data.stopEnd = false
        }, 4000)
        sessionStorage.removeItem("OinpValW")
        sessionStorage.removeItem("OinpValH")
        var obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))
        obj1.draftImages = sessionStorage.getItem('grassImg')
        var obj2 = JSON.parse(sessionStorage.getItem('FarmerInfo'));
        console.log(obj2, '-------------')
        obj1.ProjectType = obj2.ProjectType
        obj1.ComBrand = obj2.ComBrand
        obj1.ComModel = obj2.ComModel

        var aftData = JSON.parse(sessionStorage.getItem('requestFlatRoof'))
        for (const i in aftData) {
          if (i === 'eastWestLength') {
            delete aftData[i]
          }
          if (i === 'northSouthWidth') {
            delete aftData[i]
          }
          // if (i === 'eastWestLength' && i === 'gridConnectionDistance' && i === 'houseHeight' && i === 'northSouthWidth' && i === 'roofSlabThickness') {
          //   aftData[i] = aftData[i] * 100
          // }
        }
        aftData.EastWestLength = sessionStorage.getItem('inpValW') * 10
        aftData.NorthSouthWidth = sessionStorage.getItem('inpValH') * 10

        // 上传屋顶数据
        const projectJson = {
          projectAddRequest: obj1,
          requestFlatRoof: aftData,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }
        AddFlatRoof(projectJson).then(res => { // 添加平屋顶的踏勘表单数据给后台
          if (res.data.success) {
            Dialog.alert({
              message: "保存成功"
            }).then(() => {
              router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
            })
          }
        })
      },
      submit() {
        data.stopEnd = true
        setTimeout(() => {
          data.stopEnd = false
        }, 4000)
        sessionStorage.removeItem("OinpValW")
        sessionStorage.removeItem("OinpValH")
        var obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))

        obj1.draftImages = sessionStorage.getItem('grassImg')
        var obj2 = JSON.parse(sessionStorage.getItem('FarmerInfo'));
        obj1.ProjectType = obj2.ProjectType
        obj1.ComBrand = obj2.ComBrand
        obj1.ComModel = obj2.ComModel

        var aftData = JSON.parse(sessionStorage.getItem('requestFlatRoof'))
        for (const i in aftData) {
          if (i === 'eastWestLength') {
            delete aftData[i]
          }
          if (i === 'northSouthWidth') {
            delete aftData[i]
          }
          // if (i === 'eastWestLength' && i === 'gridConnectionDistance' && i === 'houseHeight' && i === 'northSouthWidth' && i === 'roofSlabThickness') {
          //   aftData[i] = aftData[i] * 100
          // }
        }

        aftData.EastWestLength = sessionStorage.getItem('inpValW') * 10
        aftData.NorthSouthWidth = sessionStorage.getItem('inpValH') * 10

        // 上传屋顶数据
        const projectJson = {
          // projectAddRequest: JSON.parse(sessionStorage.getItem('projectAddRequest')),
          projectAddRequest: obj1,
          // requestFlatRoof: JSON.parse(sessionStorage.getItem('requestFlatRoof')),
          requestFlatRoof: aftData,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }
        data.toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
          duration: 0
        })
        AddFlatRoof(projectJson).then(res => { // 添加平屋顶的踏勘表单数据给后台
          if (res.data.success) {
            AddTask(1).then(res => { // 第一次添加出图任务
              if (res.data.success) {
                data.timer = setInterval(() => { // 出图需要一定的时间,所以加循环定时器轮询出图的状态,
                  GetTaskState(1).then(ress => {
                    console.log(ress, 'ress********')
                    data.timeLessen--
                    if (ress.data.data.jsonStatus === 0 || ress.data.data.jsonStatus === 1) {
                      console.log(data.timeLessen, '计时器90s倒计时---------')
                      if (data.timeLessen === 0) {
                        Toast.clear()
                        clearInterval(data.timer)
                        Dialog.alert({
                          message: "出图失败,请重新尝试"
                        }).then(() => {
                          router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
                        })
                      }
                      // Toast.loading({
                      //   message: ress.data.data.msg,
                      //   forbidClick: true,
                      //   duration: 0
                      // })
                      // data.toast()
                    } else if (ress.data.data.jsonStatus === 2) { // 状态不为零时表示出图任务添加成功
                      clearInterval(data.timer)
                      Toast.clear()
                      Dialog.alert({
                        message: ress.data.message
                      }).then(() => {
                        router.push('/confirmLayout')
                      })
                      // clearInterval(data.timer)
                    } else if (ress.data.data.jsonStatus === 3) {
                      // 异常情况  出图任务异常处理
                      // data.second--
                      // if (data.second === 0) {
                      clearInterval(data.timer)
                      Toast.clear()
                      ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
                        if (result.data.success) {
                          // if (result.data === null) {
                          //   Dialog.alert({
                          //     message: '出图错误'
                          //   }).then(() => {
                          //     router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                          //   })
                          // }
                          Dialog.alert({
                            // message: result.data.data.errorDetail
                            message: result.data.data.errorDetail !== null ? result.data.data.errorDetail : result.data.data.error
                          }).then(() => {
                            router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                          })
                        }
                      })
                      // }
                    }
                  })
                }, 3000)
              } else {
                Toast.clear()
                Dialog.alert({
                  message: res.data.message
                })
              }
            })
          }
        })
      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
      baseInfo
    }
  }
}
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.card {
  margin: 0 auto;
  margin-top: 20px;
  padding: 16px 12px;
  width: 343px;
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  min-height: 127px;
  .title {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(199, 201, 204, 0.4);
    .left {
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
      }
    }
    span {
      display: inline-block;
      width: 4px;
      height: 19px;
      background: #04cc66;
    }
  }
  .content {
    margin-top: 15px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 14px;
    color: #7c8190;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    li:nth-last-child(1) {
      font-weight: bold;
      color: #1a1a1a;
    }
  }
}
.card1 {
  margin-top: 12px;
  border-radius: 0;
  width: 375px;
  padding: 12px 16px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 28px;
  .cancel,
  .addcancel,
  .determine {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    border-style: none;
  }
  .cancel {
// width: 118px;
    border: 1px solid #04cc66;
    color: #04cc66;
  }
  .determine {
    // width: 217px;
    background: #04cc66;
  }
  .addcancel{
    background: #8ff77a;
  }
}
.canvasimg{
  width: 100vw;
  margin-top: 20px;
  margin-left: -16px;
  height: auto;
  background-color: #628cd4;
}
</style>
